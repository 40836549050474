<template>
  <main class="settings">
    <PageHeader :title="routeName" />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </main>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    routeName() {
      return this.$route.name;
    },
  }
}
</script>

<style>

</style>